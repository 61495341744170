<!--
 * @Description:车行道 运营管理 车辆布控 carDeploy
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-06-16 21:35:52
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌号">
              <el-input v-model="searchForm.plateNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌颜色">
              <el-select class="search-input"
                         clearable
                         v-model="searchForm.numberPlateColorCode"
                         placeholder="全部">
                <el-option v-for="(item, index) in plateColorList"
                           :key="index"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="名单状态">
              <el-select class="search-input"
                         clearable
                         v-model="searchForm.listStatus"
                         placeholder="全部">
                <el-option v-for="(item, index) in listStatusList"
                           :key="index"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="resetForm">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <!-- 表格 -->
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>布控名单</span>
        <span class="tableTitleButton topButton">
          <el-button type="danger"
                     style="margin-left:17px"
                     class="tableTitleButton"
                     @click="handleClickBatchDelete">
            批量删除
          </el-button>
          <el-button type="info"
                     class="tableTitleButton"
                     @click="handleClickAdd">
            新增
          </el-button>
        </span>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  :config='tableConfig'
                  :tableFun='tableFun'
                  @getList="getList">
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="8">
                    <el-button @click="handleClickDetails(scope.row)"
                               type="text"
                               size="medium">详情</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button @click="handleClickUpdate(scope.row)"
                               type="text"
                               size="medium">修改</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               @click="handleClickDelete(scope.row)"
                               size="medium">删除</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 新增 -->
    <el-dialog title="新增"
               :visible.sync="addDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryTableList">
      <el-form :model="addFormList"
               :rules="rules"
               :inline="true"
               ref="addFormList"
               label-width="150px"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="车牌号"
                          prop="plateNumber">
              <el-input v-model="addFormList.plateNumber"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车牌颜色"
                          prop="numberPlateColorCode">
              <el-select clearable
                         v-model="addFormList.numberPlateColorCode"
                         placeholder="请选择"
                         class="dt-form-width">
                <el-option v-for="(item, index) in plateColorList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="车主姓名"
                          prop="carOwnerName">
              <el-input v-model="addFormList.carOwnerName"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="phoneNumber">
              <el-input v-model="addFormList.phoneNumber"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="身份证号"
                          prop="idCardNumber">
              <el-input v-model="addFormList.idCardNumber"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系地址"
                          prop="carOwnerContactAddress">
              <el-input v-model="addFormList.carOwnerContactAddress"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="原因"
                          prop="joinReason">
              <el-input v-model="addFormList.joinReason"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="handleClickAddConfirm('addFormList')">确 定</el-button>
          <el-button type="info"
                     class="popBtn"
                     @click="addDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog title="修改"
               :visible.sync="editDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryTableList">
      <el-form :model="editFormList"
               :rules="rules"
               :inline="true"
               ref="editFormList"
               label-width="150px"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="车牌号"
                          prop="plateNumber">
              <el-input v-model="editFormList.plateNumber"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车牌颜色"
                          prop="numberPlateColorCode">
              <el-select clearable
                         v-model="editFormList.numberPlateColorCode"
                         placeholder="请选择"
                         class="dt-form-width">
                <el-option v-for="(item, index) in plateColorList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="车主姓名"
                          prop="carOwnerName">
              <el-input v-model="editFormList.carOwnerName"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="phoneNumber">
              <el-input v-model="editFormList.phoneNumber"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="身份证号"
                          prop="idCardNumber">
              <el-input v-model="editFormList.idCardNumber"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系地址"
                          prop="carOwnerContactAddress">
              <el-input v-model="editFormList.carOwnerContactAddress"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="原因"
                          prop="joinReason">
              <el-input v-model="editFormList.joinReason"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="handleClickEditConfirm('editFormList')">确 定</el-button>
          <el-button type="info"
                     class="popBtn"
                     @click="editDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog title="详情"
               :visible.sync="detailDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryTableList">
      <el-form :model="detailFormList"
               :rules="rules"
               :inline="true"
               ref="addFormList"
               label-width="150px"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="车牌号"
                          prop="plateNumber">
              <el-input v-model="detailFormList.plateNumber"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车牌颜色"
                          prop="numberPlateColorCode">
              <el-select clearable
                         disabled
                         v-model="detailFormList.numberPlateColorCode"
                         placeholder="请选择"
                         class="dt-form-width">
                <el-option v-for="(item, index) in plateColorList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="车主姓名"
                          prop="carOwnerName">
              <el-input v-model="detailFormList.carOwnerName"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="phoneNumber">
              <el-input v-model="detailFormList.phoneNumber"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="身份证号"
                          prop="idCardNumber">
              <el-input v-model="detailFormList.idCardNumber"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系地址"
                          prop="carOwnerContactAddress">
              <el-input v-model="detailFormList.carOwnerContactAddress"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="原因"
                          prop="joinReason">
              <el-input v-model="detailFormList.joinReason"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="info"
                     class="popBtn"
                     @click="detailDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import dtTable from '@/components/table.vue'
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      tableList: { list: [] },
      listMap: { pageNum: 1, pageSize: 10 },
      tableFun: { 'selection-change': this.handleChange },
      tableColumnList: [
        {
          prop: 'plateNumber',
          label: '车牌号',
        },
        {
          prop: 'numberPlateColor',
          label: '车牌颜色',
        },
        {
          prop: 'listStatusName',
          label: '名单状态',
        },
        {
          prop: 'joinReason',
          label: '加入名单原因',
        },
      ],
      // 查询参数
      searchForm: {},
      pageNum: 1,
      pageSize: 15,

      addFormList: {},// 新增表单
      addDialogVisible: false, // 新增弹窗

      detailFormList: {},// 详情表单
      detailDialogVisible: false, // 详情弹窗

      editFormList: {},// 详情表单
      editDialogVisible: false, //修改弹窗

      rules: {
        //车牌号校验
        plateNumber: [
          {
            required: true,
            message: '请输入',
            trigger: 'blur'
          },
          {
            pattern: /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1})$/,
            message: '请输入正确的车牌号',
            trigger: 'blur'
          }
        ],
        //车辆颜色校验
        numberPlateColorCode: [
          {
            required: true,
            message: '请输入',
            trigger: 'change'
          }
        ],
        //手机号校验
        phoneNumber: [
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: '请输入正确的手机号',
            trigger: 'blur'
          }
        ],
        //身份证号
        idCardNumber: [
          {
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: '请输入正确的身份证号',
            trigger: 'blur'
          }
        ]
      },
      plateColorList: [], // 车牌颜色列表
      listStatusList: [
        { name: '启用', code: 1 },
        { name: '禁用', code: 2 },
      ],// 状态
      massDeleteList: []// 多选操作
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryColorList()
    this.queryTableList()
  },
  //方法集合
  methods: {
    // 获取数据
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum
      this.searchForm['pageSize'] = this.pageSize
      this.$parkCarDeploy.queryControlVehicleListNoAuth(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 查询
    onSubmit () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    // 重置
    resetForm () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.searchForm = {}
      this.queryTableList()
    },
    // 详情
    handleClickDetails (row) {
      this.detailFormList = {}
      this.detailFormList = row
      this.detailDialogVisible = true
    },
    // 新增
    handleClickAdd () {
      this.addFormList = {}
      this.addDialogVisible = true
    },
    // 新增确认
    handleClickAddConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$parkCarDeploy.insert(this.addFormList).then((response) => {
            if (response.resultCode == 2000) {
              this.$message({ message: '新增成功', type: 'success' });
              this.addDialogVisible = false
              this.queryTableList()
            }
          })
        }
      })
    },
    // 修改
    handleClickUpdate (row) {
      this.editFormList = {}
      this.editFormList = row
      this.editDialogVisible = true
    },
    // 修改确认
    handleClickEditConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //this.cleanObj(this.addFormList)
          this.$parkCarDeploy.update(this.editFormList).then((response) => {
            if (response.resultCode == 2000) {
              this.$message({ message: '修改成功', type: 'success' });
              this.editDialogVisible = false
              this.queryTableList()
            }
          })
        }
      })

    },
    // 删除
    handleClickDelete (row) {
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$parkCarDeploy.deleteBatch([{ listId: row.listId }]).then(res => {
          if (res.resultCode == 2000) {
            this.$message({ message: '删除成功', type: 'success' });
            this.queryTableList()
          }
        })
      });
    },
    // 多选操作
    handleChange (val) {
      let arr = []
      val.forEach((item) => {
        arr.push({ listId: item.listId })
      })
      this.massDeleteList = arr
    },
    // 批量删除
    handleClickBatchDelete () {
      if (this.massDeleteList.length === 0) {
        this.$message({ showClose: true, message: '至少勾选一条记录', type: 'error' });
      } else {
        this.$confirm('确定批量删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let info = this.massDeleteList
          this.$parkCarDeploy.deleteBatch(info).then((response) => {
            if (response.resultCode == 2000) {
              this.$message({ message: '删除成功', type: 'success' });
              this.queryTableList()
            }
          })
        })
      }
    },
    //获取车牌颜色
    queryColorList () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2',
          },
        ]
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.plateColorList = response.resultEntity
      })
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
// 最外层div
.mainbody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 230px;
}
</style>
